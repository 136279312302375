import moment from "moment";
import { IGroup } from "office-ui-fabric-react";
import PubSub from "pubsub-js";
import { GQLReport } from "../schemas/schema";

export const emptyFunc = () => null;

export const DateFormats = {
  Date: "DD MMM Y",
  Short_Date: "MM/DD/Y",
  DateTime: "DD MMM Y hh:mm",
  DateAlt: "MMM DD, Y",
  Time: "h:mm a zz"
};

export const onlyVisibleIndicators = (x) => x.visible != "EXCLUDED";
export function onlyEssentialIndicators(topic) {
  if (!topic) {
    return false;
  }
  if (!topic.emphasis_tier) {
    return false;
  }
  if (!topic.emphasis_tier.id) {
    return false;
  }

  if (topic.emphasis_tier.id === 1) {
    return true;
  } else {
    return false;
  }
}
export function distinct(value, index, self) {
  return self.indexOf(value) === index;
}
export function distinctBy(func) {
  let list = [];
  return function(value, index, self) {
    if (list.length != self.length) {
      list = self.map(func);
    }
    return distinct(func(value), index, list);
  };
}

export function navigate_client_report(report: GQLReport) {
  return navigate(`/clients/${report.client!.id}/reports/${report.id}/`, false);
}

export function navigate_client_report_redirect(report: GQLReport) {
  return navigateRedirect(`/clients/${report.client!.id}/reports/${report.id}/`, false);
}

const getUrl = (url, prefix = true) => {
  if (prefix) {
    url = "/admin/" + url;
  }
  url = url.replace(/\/\//g, "/");
  return url;
};

export function navigate(url: string, prefix = true): (e?: any) => void {
  url = getUrl(url, prefix);
  return function(e?: MouseEvent) {
    if (e && e.ctrlKey) {
      window.open(url);
    } else {
      window.goto(url);
    }
  };
}

export function navigateTo(...args) {
  const out = {};
  // @ts-expect-error
  out.onClick = navigate(...args);
  // @ts-expect-error
  out.href = getUrl(...args);

  return out;
}

export function navigateRedirect(url: string, prefix = true): (e?: any) => void {
  if (prefix) {
    url = "/admin/" + url;
  }
  return function(e?: MouseEvent) {
    window.location.href = url;
  };
}

export function friendlyDate(date: string, format = DateFormats.Date): string {
  return moment(date).format(format);
}

export function friendlyDateTime(date: string): string {
  return moment(date).format(DateFormats.DateTime);
}

export async function getBase64(file) {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve({ val: reader.result!, file });
    reader.onerror = (error) => reject(error);
  });
}

export function make_groups_from_items(items: any[], getKey: (item) => string, startIndex = 0): IGroup[] {
  if (!items.length) {
    return [];
  }
  let index = startIndex;
  const groups = [] as IGroup[];
  let group: string = "";
  let groupCount = 0;
  for (const item of items) {
    const item_group_name = getKey(item);
    if (group != item_group_name) {
      if (groups.length > 0) {
        groups[groups.length - 1].count = groupCount;
        groupCount = 0;
      }
      group = item_group_name;
      groups.push({
        key: group,
        name: group,
        startIndex: index,
        count: 0
      });
    }
    index++;
    groupCount++;
  }
  // if (!groups.length) { return []; }
  groups[groups.length - 1].count = index - groups[groups.length - 1].startIndex;

  return groups;
}

async function permissions_are_ready() {
  return await new Promise((resolve, reject) => {
    if (window.permissions_ready) {
      resolve(true);
    } else {
      PubSub.subscribe("permissions.ready", resolve);
    }
  });
}

// const makeCancelable = promise => {
//     const wrappedPromise = new Promise((resolve, reject) => {
//         if (promise.cancelled) {
//             debugger;
//         }

//         Promise.resolve(promise)
//             .then(resolve)
//             .catch(reject);
//     });

//     wrappedPromise.cancel = () => {
//         promise.cancelled = true;
//         debugger
//     };

//     return wrappedPromise;
// };

async function _checkPermission(permission_name) {
  await permissions_are_ready();
  return await new Promise((resolve, reject) => {
    console.log(`checking permission ${permission_name}`);
    PubSub.publishSync("user.has_permission", {
      permission: permission_name,
      reject: () => {
        console.log(`permission ${permission_name} denied`);
        resolve(false);
      },
      callback: () => {
        console.log(`permission ${permission_name} granted`);
        resolve(true);
      }
    });
  });
}

export async function checkPermission(permission_name) {
  return await _checkPermission(permission_name);
}

export function flatten(array: any[]): any[] {
  return [].concat(...array) as any[];
}

export function ensureArray(i: any) {
  return Array.isArray(i) ? i : [i];
}

export function copyParameters(source, target, params, allowNull = false) {
  const isBlank = (val) => val === undefined || (val === null && !allowNull);

  for (const p of params) {
    if (isBlank(source[p])) {
      continue;
    }
    target[p] = source[p];
  }
}

export function percent(n) {
  if (isNaN(n)) {
    return 0;
  }
  return Math.round(n * 100);
}

export function views_from_db(views = [] as any[]) {
  let topic_id = null;
  let track_id = null;
  const out = [] as any[];
  for (const i of views) {
    if (i.topic_id != topic_id || i.track_id != track_id) {
      out.push({ topic_id: i.topic_id, track_id: i.track_id, indicators: [] });
    }
    // items[items.length-1].indicators = items[items.length-1].indicators.concat(i.indicators);
    out[out.length - 1].indicators.push(i.id + "");
    topic_id = i.topic_id;
    track_id = i.track_id;
  }
  return out;
}

export function reportId() {
  const path = window.location.pathname;
  // @ts-expect-error
  return path.match(/\/reports\/([0-9]+)/)[1];
}

export function reportTrackId() {
  const path = window.location.pathname;
  // @ts-expect-error
  return path.match(/\/reports\/[0-9]+\/tracks\/([0-9]+)/)[1];
}

export function toReportEdit() {
  return `/reports/${reportId()}/edit`;
}

export function toDropdownOption(x) {
  return {
    key: x.id,
    text: x.name
  };
}
