import React from "react";
import { GQLComment, GQLReport, GQLReportTrack, GQLUser } from "../../schemas/schema";
import { LocalContext } from "./LocalContext";

interface IReviewAppContext {
  report_track?: GQLReportTrack
  report?: GQLReport
  current_user?: GQLUser
  comments?: GQLComment[]
  localContext?: LocalContext
  renderEssentialIndicatorsOnly?: boolean
  toggleRenderEssentialIndicatorsOnly: () => void
}

export const ReviewAppContext = React.createContext({} as IReviewAppContext);
